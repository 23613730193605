.contact-page {
  font-size: 1rem;

  h1 {
    font-size: 4rem;

    @media (max-width: 576px) {
      font-size: 3rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.about-us {
  .modal-open & {
    filter: blur(7px);
  }
}
