// global styles
a {
  cursor: pointer;
}

.app-container {
  min-height: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.admin-nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #e8e9ea;
  border-bottom: 1px solid #ccc;
}

.link {
  cursor: pointer;
}

.media-item {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: inline-flex;
  position: relative;

  &.active {
    border: 1px solid green;
  }

  button {
    display: none;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    button {
      display: block;
    }
  }
}

.add-items-modal {
  z-index: 1050 !important;
}

.select-media {
  display: flex;

  button:not(.remove-btn) {
    height: 100px;
  }
}

.select-media-modal {
  height: calc(100% - 200px) !important;
}

.video-list {
  .details {
    padding-left: 10px;
    font-size: 12px;
  }
}
