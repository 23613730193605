@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "./variables";

$custom-spacers: (
  6: $spacer * 4,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 10,
  10: $spacer * 12,
);

$spacers: map-merge($spacers, $custom-spacers);

$custom-breakpoints: (
  3xl: 1600px,
  4xl: 1800px,
  5xl: 2000px,
);

$grid-breakpoints: map-merge($grid-breakpoints, $custom-breakpoints);

$custom-container-max-widths: (
  3xl: 1520px,
  4xl: 1720px,
  5xl: 1920px,
);

$container-max-widths: map-merge(
  $container-max-widths,
  $custom-container-max-widths
);

@import "~bootstrap/scss/mixins";
@import "./mixins";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@import "./admin/styles";
@import "./_components/components";

html {
  height: 100vh;
}

html,
body,
#root {
  // &:not(.about),
  // &:not(.contact) {
  //   min-height: 100vh;
  // }
  //

  &.menu-opened,
  &.modal-open {
    overflow: hidden;
    touch-action: none;
  }
}

body {
  background: url("../assets/app-bg.png");
  color: getColor($primary-color, "light");
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  overflow-x: hidden;
  padding-top: 94px;
  touch-action: pan-y;

  &.about,
  &.contact {
    height: 100vh;

    #root {
      height: calc(100vh - 100px);
    }
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  }

  &.dark {
    background: getColor($navbar-bg, "dark");
    color: getColor($primary-color, "dark");
  }

  &.menu-opened,
  &.modal-open {
    overflow: hidden;
    touch-action: none;
  }

  .modal-open & {
    overflow: hidden;
  }
}

strong {
  font-weight: 600;
}

h1.main-title,
.h1.main-title,
h2.main-title,
.h2.main-title {
  @include getTextColor($primary-color);

  font-weight: 300;
}

h1.main-title,
.h1.main-title {
  font-size: 5rem;

  @media (max-width: 576px) {
    font-size: 3rem;
  }

  @media (max-width: 320px) {
    font-size: 2.5rem;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.modal {
  height: 100vh;
}

// Disable a11y features
button:focus-visible {
  outline: none;
}

.masonry {
  margin: 0 auto;
  width: 100% !important;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 10px;
}
