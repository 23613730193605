@function getColor($color, $property) {
  @return map-get($color, $property);
}

@mixin getBgColor($color, $opacity: 100) {
  background-color: rgba(getColor($color, "light"), $opacity) !important;

  .dark & {
    background-color: rgba(getColor($color, "dark"), $opacity) !important;
  }
}

@mixin getTextColor($color) {
  color: getColor($color, "light");

  .dark & {
    color: getColor($color, "dark");
  }
}
