@import "~bootstrap/scss/mixins";
@import "../../styles/variables";
@import "../../styles/mixins";

.list-items {
  .modal-open & {
    filter: blur(7px);
  }

  .item {
    position: relative;

    .item-content {
      top: 100px;
      opacity: 0;
      -webkit-transition: all 1s ease;
      -webkit-transition: -webkit-all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all ease 1s;
    }

    &.in-viewport {
      .item-content {
        top: 0;
        opacity: 1;
      }
    }

    img {
      opacity: 0;
      -webkit-transition: all 0.5s ease;
      -webkit-transition: -webkit-all 1s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;

      &.loaded {
        opacity: 1;
      }
    }

    > * {
      cursor: pointer;
    }

    .play-button {
      background: #000000;
      border-radius: 50px;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 20px #858585;

      div {
        svg {
          opacity: 0.8;
        }
      }

      @media (max-width: 576px) {
        width: 40px;
        height: 40px;

        svg {
          width: 12px;
        }
      }
    }

    .text-wrapper {
      top: 100px;
      opacity: 0;
      position: relative;
      -webkit-transition: all 1s ease;
      -webkit-transition: -webkit-all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all ease 1s;

      &.in-viewport {
        top: 0;
        opacity: 1;
      }
    }

    .video-preview {
      background-color: white;
      opacity: 0;
      //transition: opacity 0.5s;

      .dark & {
        background-color: getColor($navbar-bg, "dark");
      }

      &.visible {
        opacity: 1;
      }

      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
      }
    }

    .item-title {
      font-size: 2.125rem;
    }

    .item-description {
      color: #777777;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
